import {Button, Modal} from "antd";
import {UserType} from "../../../store/admin/usersList/usersList.types";
import {useState} from "react";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {updateUser} from "../../../store/admin/usersList/usersList.action";

interface BlockUserModalProps {
  user: UserType;
}

export const BlockUserModal = ({user}: BlockUserModalProps) => {
  
  const [isOpened, setIsOpened] = useState(false);
  
  const dispatch = useAppDispatch();
  
  const handleOk = () => {
    dispatch(updateUser({isActive: !user.isActive}, user?.id as string))
    setIsOpened(false);
  }
  
  return (
    <>
      <Button
        type={'primary'}
        danger={user.isActive}
        onClick={() => setIsOpened(true)}
      >
        {user.isActive ? 'Block' : 'Activate'}
      </Button>
      {
        user ?
          <Modal title={user.isActive ? 'Block user' : 'Activate user'} onOk={handleOk}
                 onCancel={() => setIsOpened(false)} open={isOpened}>
            <p>Are you sure you want
              to {user.isActive ? 'block' : 'activate'} user {user.firstName} {user.lastName}?</p>
          </Modal>
          : null
      }
    </>
  )
}