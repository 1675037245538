import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {SearchUsers} from "../../../components/admin/users/searchUsers";
import {Space, Table} from "antd";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useEffect, useLayoutEffect, useState} from "react";
import {getUsersList} from "../../../store/admin/usersList/usersList.action";
import moment from "moment";
import {BlockUserModal} from "../../../components/admin/users/blockUserModal";
import {UserType} from "../../../store/admin/usersList/usersList.types";
import {EditUserModal} from "../../../components/admin/users/editUserModal";
import uuid from "react-uuid";

export const UsersTable = () => {

  document.title = 'Users';

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useAppDispatch();

  const {users, meta, isSearching} = useAppSelector(state => state.admin.users);

  useLayoutEffect(() => {
    dispatch(getUsersList({page: currentPage, take: pageSize}))
  }, [dispatch])

  useEffect(() => {
    !isSearching && dispatch(getUsersList({page: currentPage, take: pageSize}))
  }, [currentPage, pageSize])


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
      <>
        <SearchUsers/>
        <Table
            pagination={{
              current: currentPage,
              total: meta.itemCount,
              onChange: handlePageChange,
              onShowSizeChange: handlePageSizeChange,
            }}
            dataSource={users}
            style={{width: '100%', minHeight: '80vh'}}
        >
          <Table.Column title={'First Name'} dataIndex={'firstName'}/>
          <Table.Column title={'Last Name'} dataIndex={'lastName'}/>
          <Table.Column title={'Email'} dataIndex={'email'}/>
          <Table.Column title={'Role'} dataIndex={'role'}/>
          <Table.Column
              title={'Active'}
              dataIndex={'isActive'}
              render={(isActive: boolean) => {
                return isActive ? 'Yes' : 'No';
              }}
          />
          <Table.Column title={'Requests per day'} dataIndex={'requestsAvailable'}/>
          <Table.Column title={'Requests remaining'} dataIndex={'requestsRemaining'}/>
          <Table.Column
              title={'Created at'}
              dataIndex={'createdAt'}
              render={(date: string) => {
                return moment(date).format('LLL').toString();
              }
              }
          />
          <Table.Column
              title={'Last activity'}
              dataIndex={'updatedAt'}
              render={(date: string) => {
                return moment(date).format('LLL').toString();
              }
              }
          />
          <Table.Column
              title={'Actions'}
              key={uuid()}
              render={(record: UserType) => {
                return (
                    <Space size={'middle'}>
                      <EditUserModal
                          user={record}
                      />
                      <BlockUserModal
                          user={record}
                      />
                    </Space>
                )
              }}
          />
        </Table>
      </>
  )
}