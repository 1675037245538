import {Button, Layout, Row} from "antd";
import React, {useEffect} from "react";
import logo from '../assets/Accelerate-Tax-Logo-light.svg';
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../hooks/useAppSelector";
import {authMe} from "../store/user/user.action";
import {useAppDispatch} from "../hooks/useAppDispatch";
import {AddUserModal} from "./admin/users/addUserModal";

export const Header = () => {
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if(localStorage.getItem('token') && !user) {
      dispatch(authMe())
    }
  }, [dispatch])
  
  const navigate = useNavigate();
  const {user} = useAppSelector(state => state.user);
  
  return (
    <Layout.Header className={'header'}>
      <Row
        align={'middle'}
        style={{width: '100%', gap: '40px'}}
      >
        <img alt={'logo'} style={{width: '200px', marginRight: 'auto'}} src={logo}/>
        {user && user.user.role === 'ADMIN' &&
            <>
                <Button
                    type={'primary'}
                    onClick={() => navigate('/admin/users')}
                >
                    Admin
                </Button>
                <AddUserModal/>
            </>
        }
        {
          user &&
          <Button
            type={'primary'}
            onClick={() => {
              localStorage.removeItem('token');
              navigate('/login');
            }}
          > Logout </Button>
        }
      </Row>
    </Layout.Header>
  )
}