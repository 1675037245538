import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {Layout} from "antd";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Login} from "./pages/login/login";
import {Header} from "./components/header";
import {store} from "./store/store";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import {Router} from "./HOC/router";
import {CreateAdmin} from "./pages/admin/create/createAdmin";
import {Main} from "./pages/main/main";
import {UsersTable} from "./pages/admin/users/usersTable";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
        position={"bottom-right"}
      />
      <BrowserRouter>
        <Router>
          <Layout className={'layout'}>
            <Header/>
            <Layout>
              <Layout.Content className={'main-layout'}>
                <Routes>
                  <Route path={'/'} element={<Main/>}/>
                  <Route path={'/login'} element={<Login/>}/>
                  <Route path={'/admin/create'} element={<CreateAdmin/>}/>
                  <Route path={'/admin/users'} element={<UsersTable/>}/>
                </Routes>
              </Layout.Content>
            </Layout>
          </Layout>
        </Router>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
