import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../hooks/useAppSelector";
import React, {PropsWithChildren, useEffect} from "react";

export const Router = (props: PropsWithChildren) => {
  const navigate = useNavigate();
  const {to} = useAppSelector(state => state.redirect);
  
  useEffect(() => {
    if(to)
      navigate(to);
  }, [to, navigate]);
  
  return (
    <>
      {props.children}
    </>
  )
}