const FIND_USERS_REQUEST = 'FIND_USERS_REQUESTS';
const FIND_USERS_SUCCESS = 'FIND_USERS_SUCCESS';
const FIND_USERS_FAILURE = 'FIND_USERS_FAILURE';
const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

type UserType = {
  id: string,
  createdAt: string,
  updatedAt: string,
  firstName: string,
  lastName: string,
  role: string,
  email: string,
  isActive: boolean,
  requestsRemaining: number,
  requestsAvailable: number
}

type UsersListMeta = {
  page: number,
  take: number,
  pageCount: number,
  itemCount: number
  hasNextPage: boolean,
  hasPreviousPage: boolean,
}

type UsersListState = {
  users: UserType[],
  meta: UsersListMeta,
  isLoading: boolean,
  isSearching: boolean,
  error: null | Error
}

type UserListAction = {
  meta: UsersListMeta,
  data: UserType[]
}

type UpdateUserType = {
  firstName?: string,
  lastName?: string,
  role?: string,
  isActive?: boolean,
  requestsAvailable?: number,
  password?: string
}

type CreateUserType = {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  requestsAvailable: number,
  requestsRemaining: number,
}

export type {
  UserType,
  UsersListState,
  UserListAction,
  UsersListMeta,
  UpdateUserType,
  CreateUserType
}

export {
  FIND_USERS_FAILURE,
  FIND_USERS_SUCCESS,
  FIND_USERS_REQUEST,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USERS_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  CREATE_USER_FAILURE,
  CREATE_USER_SUCCESS,
  CREATE_USER_REQUEST
}