import {Button, Form as AntForm, Input, Select} from 'antd';
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useLayoutEffect} from "react";
import {fetchCategories} from "../../../store/categories/categories.action";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {RootState} from "../../../store/store";
import {FormTypes} from "./form.types";
import {fetchData} from "../../../store/data/data.action";

export const Form = () => {
  
  const dispatch = useAppDispatch();
  
  useLayoutEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])
  
  const {categories} = useAppSelector((state: RootState) => state.categories);
  
  const onFinish = (values: FormTypes) => {
    dispatch(fetchData(values))
  }

  return (
    <AntForm
      layout={'inline'}
      onFinish={onFinish}
      style={{marginBottom: '20px', marginRight: 'auto'}}
    >
      <AntForm.Item
        label={'Zip code'}
        name={'zip'}
        rules={[{ required: true, message: 'Please input ZIP!' }]}
      >
        <Input placeholder={'Input Zip'}/>
      </AntForm.Item>
      <AntForm.Item
        label={'Category'}
        name={'category'}
        initialValue={'All'}
        rules={[{ required: true, message: 'Please select the category!' }]}
      >
        <Select
          style={{width: '200px'}}
          defaultValue={'All'}
          options={[
            {
              label: 'All',
              value: 'All'
            },
            ...categories.map((category) => {
              return {
                label: category,
                value: category
              }
            })
          ]}
        />
      </AntForm.Item>
      <AntForm.Item>
        <Button type={'primary'} htmlType={'submit'}>Submit</Button>
      </AntForm.Item>
    </AntForm>
  );
}