import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {findUsers, getUsersList} from "../../../store/admin/usersList/usersList.action";
import {Button, Form, Input, Row} from "antd";

export const SearchUsers = () => {
  
  const dispatch = useAppDispatch();
  
  const onFinish = (values: {search: string}) => {
    if(values.search === '')
      dispatch(getUsersList({page: 1, take: 10}))
    else
      dispatch(findUsers(values.search))
  }

  return (
    <Row style={{width: '100%', marginBottom: '20px'}}>
      <Form
        style={{width: '100%'}}
        layout={'inline'}
        onFinish={onFinish}
      >
        <Form.Item
          style={{width: '25%'}}
          required={true}
          name={'search'}
        >
          <Input placeholder={'Search by first name, last name and email'}/>
        </Form.Item>
        <Form.Item>
          <Button type={'primary'} htmlType={'submit'}>Search</Button>
        </Form.Item>
      </Form>
    </Row>
  )
}