const REDIRECT = 'REDIRECT';

type RedirectState = {
  to: string;
}

export {
  REDIRECT
}

export type {
  RedirectState
}