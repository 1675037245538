import {
  CategoriesState,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS
} from "./categories.types";
import {ActionWithPayload} from "../index.types";

const initialState: CategoriesState = {
  categories: [],
  isLoading: false,
  error: null,
}
export const categoriesReducer = (state = initialState, action: ActionWithPayload<string[] | Error>): CategoriesState => {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.payload as string[],
      }
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload as Error
      }
    default:
      return state;
  }
}