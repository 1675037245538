import {useAppSelector} from "../../../hooks/useAppSelector";
import {RootState} from "../../../store/store";
import {Table as AntTable} from 'antd';
import uuid from "react-uuid";


export const Table = () => {
  
  const {data, isLoading} = useAppSelector((state: RootState) => state.data);
  const {categories} = useAppSelector((state: RootState) => state.categories);
  const {Column} = AntTable;
  
  return (
    <AntTable
      loading={isLoading}
      dataSource={data.map((obj) => {
        return {
          ...obj,
          key: uuid()
        }
      })}
      style={{width: '100%', minHeight: '80vh'}}
    >
      <Column width={'15%'} title={'Country'} dataIndex={'countyname'} key={'countyname'}/>
      <Column
        width={'5%'}
        title={'Category'}
        dataIndex={'categoryname'}
        key={'categoryname'}
        filters={categories.map((category) => {
          return {
            text: category,
            value: category
          }
        })}
        onFilter={(value, record: any) => record.categoryname.indexOf(value) === 0}
      />
      <Column width={'10%'} title={'Policy'} dataIndex={'policyname'} key={'policyname'}/>
      <Column width={'5%'} title={'Start Date'} dataIndex={'startdate'} key={'startdate'}/>
      <Column
        title={'URL'}
        dataIndex={'startdate_url'}
        key={'startdate_url'}
        render={(url: string) => (
          <a href={url} target={'_blank'} rel="noreferrer">{url}</a>
        )}
      />
    </AntTable>
  );
}