import {REDIRECT, RedirectState} from "./redirect.types";
import {ActionWithPayload} from "../index.types";

const initialState: RedirectState = {
  to: '',
}

export const redirectReducer = (state = initialState, action: ActionWithPayload<string>): RedirectState => {
  switch (action.type) {
    case REDIRECT:
      return {
        ...state,
        to: action.payload,
      }
    default:
      return state;
  }
}