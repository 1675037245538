import {
  DataState, DataType,
  GET_DATA_FAILURE,
  GET_DATA_REQUEST,
  GET_DATA_SUCCESS
} from "./data.types";
import {ActionWithPayload} from "../index.types";

const initialState: DataState = {
  data: [],
  isLoading: false,
  error: null,
}

export const dataReducer = (state = initialState, action: ActionWithPayload<DataType[] | Error>): DataState => {
  switch (action.type) {
    case GET_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as DataType[],
      }
    case GET_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload as Error
      }
    default:
      return state;
  }
}
