import {Dispatch} from "redux";
import {
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST, CREATE_USER_SUCCESS,
  CreateUserType,
  FIND_USERS_FAILURE,
  FIND_USERS_REQUEST,
  FIND_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS, UPDATE_USER_FAILURE, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UpdateUserType, UserListAction
} from "./usersList.types";
import {UsersListService} from "./usersList.service";
import {toast} from "react-toastify";


export const getUsersList = (data: { page: number, take: number }): (dispatch: Dispatch) => void => {
  
  return (dispatch) => {
    dispatch({type: GET_USERS_REQUEST});
    UsersListService.getUsersList(data.page, data.take).then((data: UserListAction) => {
      dispatch({type: GET_USERS_SUCCESS, payload: data});
    }).catch((error => {
      dispatch({type: GET_USERS_FAILURE, payload: error});
    }))
  }
}

export const findUsers = (search: string): (dispatch: Dispatch) => void => {
  
  return (dispatch) => {
    if(search) {
      dispatch({type: FIND_USERS_REQUEST});
      UsersListService.searchUsers(search).then((data) => {
        dispatch({type: FIND_USERS_SUCCESS, payload: data});
      }).catch((error => {
        dispatch({type: FIND_USERS_FAILURE, payload: error});
      }))
    }
  }
}

export const updateUser = (data: UpdateUserType, id: string): (dispatch: Dispatch) => void => {
  
  return (dispatch) => {
    dispatch({type: UPDATE_USER_REQUEST});
    UsersListService.updateUser(data, id).then((data) => {
      dispatch({type: UPDATE_USER_SUCCESS, payload: data});
    }).catch((error => {
      dispatch({type: UPDATE_USER_FAILURE, payload: error});
    }))
  }
}

export const createUser = (data: CreateUserType): (dispatch: Dispatch) => void => {
  return (dispatch) => {
    dispatch({type: CREATE_USER_REQUEST});
    const notification = toast.loading("Creating...", {autoClose: 2000});
    UsersListService.createUser(data).then((data) => {
      dispatch({type: CREATE_USER_SUCCESS, payload: data});
      toast.update(notification, {render: "Created!", type: "success", isLoading: false, autoClose: 2000});
    }).catch((error => {
      dispatch({type: CREATE_USER_FAILURE, payload: error});
      if (error.response.status === 409)
        toast.update(notification, {render: "User with this email already exists!", type: "error", isLoading: false, autoClose: 2000});
      else if (error.response.status === 429)
        toast.update(notification, {render: "Too many requests!", type: "error", isLoading: false, autoClose: 2000});
      else
        toast.update(notification, {render: "Error!", type: "error", isLoading: false, autoClose: 2000});
    }))
  }
}

