import axios from "axios";
import {toast} from "react-toastify";
axios.interceptors.request.use(
  (config) =>
  {
    let token = localStorage.getItem('token');
    if (token)
    {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    config.headers["Content-Type"] = 'application/json';
    return config;
  },
  (error) =>
  {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) =>
  {
    return response;
  },
  (error) =>
  {
    if(error.response.status === 401)
    {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    if(error.response.status === 429) {
      toast("Too many requests!", {type: "error", autoClose: 2000})
    }
    return Promise.reject(error);
  }
);

export const get = (endpoint: string) => {
  return axios.get(process.env.REACT_APP_API_URL + endpoint)
    .then(
      (response) => {
        return response.data;
      }
    );
}
export const post = <T>(endpoint: string, body: T) => {
  return axios.post(process.env.REACT_APP_API_URL + endpoint, body)
    .then(
      (response) => {
        return response.data;
      }
    )
}

export const put = <T>(endpoint: string, body: T) => {
  return axios.put(process.env.REACT_APP_API_URL + endpoint, body)
    .then(
      (response) => {
        return response.data;
      }
    );
}

export const del = (endpoint: string) => {
  return axios.delete(process.env.REACT_APP_API_URL + endpoint)
    .then(
      (response) => {
        return response.data;
      }
    );
}