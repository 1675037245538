import {UserService} from "./user.service";
import {toast} from "react-toastify";
import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AuthLoginType,
  CreateAdminType, UserDataType,
  UserType
} from "./user.types";
import {Dispatch} from "redux";
import {REDIRECT} from "../redirect/redirect.types";

const auth = (data: AuthLoginType): (dispatch: Dispatch) => void => {
  
  return (dispatch) => {
    
    const notification = toast.loading("Accessing...", {autoClose: 2000});
    dispatch({type: AUTH_LOGIN_REQUEST});
    
    UserService.authenticate(data).then(
      (response: UserType) => {
        toast.update(notification, {render: "Welcome!", type: "success", isLoading: false, autoClose: 2000});
        localStorage.setItem('token', response.token.accessToken);
        dispatch({type: AUTH_LOGIN_SUCCESS, payload: response});
        dispatch({type: REDIRECT, payload: '/'})
      }
    ).catch((error: {response: {status: number}}) => {
      if(error.response.status === 401)
        toast.update(notification, {render: "Wrong credentials!", type: "error", isLoading: false, autoClose: 2000});
      else
        toast.update(notification, {render: "Something went wrong!", type: "error", isLoading: false, autoClose: 2000});
      
      if(error.response.status === 404)
        toast.update(notification, {render: "User not found!", type: "error", isLoading: false, autoClose: 2000});
      
      if(error.response.status === 429)
        toast.update(notification, {render: "Too many attempts!", type: "error", isLoading: false, autoClose: 2000});
      
      dispatch({type: AUTH_LOGIN_FAILURE, payload: error});
    }).finally(() => {
      toast.dismiss(notification);
      dispatch({type: REDIRECT, payload: ''})
    })
  }
}

const createAdmin = (data: CreateAdminType): (dispatch: Dispatch) => void => {
  return (dispatch) => {
    const notification = toast.loading("Creating...", {autoClose: 2000});
    UserService.createAdmin(data).then(
      () => {
        toast.update(notification, {render: "Admin created!", type: "success", isLoading: false, autoClose: 2000});
        dispatch({type: REDIRECT, payload: '/login'})
      }
    ).catch((error: {response: {status: number, data: { error: string}}}) => {
      toast.update(notification, {render: error.response.data.error, type: "error", isLoading: false, autoClose: 2000});
    }).finally(() => {
      toast.dismiss(notification);
    })
  }
}

const authMe = (): (dispatch: Dispatch) => void => {
  return (dispatch) => {
    UserService.fetchMe().then(
      (response: UserDataType) => {
        dispatch({type: AUTH_LOGIN_SUCCESS, payload: {
          user: response,
          token: {accessToken: localStorage.getItem('token') as string, expiresIn: 0}
        }});
      }
    ).catch((error: {response: {status: number}}) => {
      dispatch({type: AUTH_LOGIN_FAILURE, payload: error});
      if(error.response.status === 429)
        toast("Too many attempts!", {type: "error", autoClose: 2000})
    })
  }
}

export {
  auth,
  createAdmin,
  authMe
}