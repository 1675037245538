import {get, post} from "../../middleware/api-proxy";
import {AuthLoginType, CreateAdminType} from "./user.types";

export class UserService {
  static async authenticate(data: AuthLoginType) {
    return await post<AuthLoginType>("/auth/login", data)
  }
  
  static async createAdmin(data: CreateAdminType) {
    return await post<CreateAdminType>("/users/create-admin", data)
  }
  
  static async fetchMe() {
    return await get("/auth/me")
  }
}