import {ActionWithPayload} from "../../index.types";
import {
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST, CREATE_USER_SUCCESS,
  FIND_USERS_FAILURE,
  FIND_USERS_REQUEST, FIND_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS, UserListAction,
  UsersListState,
  UserType
} from "./usersList.types";

const initialState: UsersListState = {
  users: [],
  meta: {
    page: 0,
    take: 0,
    pageCount: 0,
    itemCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  },
  isLoading: false,
  isSearching: false,
  error: null
}
export const usersListReducer = (state = initialState, action: ActionWithPayload<UserListAction | UserType[] | UserType | Error>): UsersListState => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case GET_USERS_SUCCESS:
        const data = () => {
          const data = action.payload as UserListAction
          return {
            meta: data.meta,
            users: data.data
          }
        }
      return {
        ...state,
        isLoading: false,
        isSearching: false,
        ...data()
      }
    case GET_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload as Error
      }
    case FIND_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case FIND_USERS_SUCCESS:
      const userCount = action.payload as UserType[]
      return {
        ...state,
        isLoading: false,
        users: action.payload as UserType[],
        meta: {
          ...state.meta,
          page: 0,
          take: 0,
          pageCount: 0,
          itemCount: userCount.length,
          hasNextPage: false,
          hasPreviousPage: false
        },
        isSearching: true
      }
    case FIND_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload as Error
      }
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: state.users.map(user => {
          if (user.id === (action.payload as UserType).id) {
            return action.payload as UserType
          }
          return user
        })
      }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload as Error
      }
    case CREATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: [...state.users, action.payload as UserType]
      }
    case CREATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload as Error
      }
    
    default:
      return state;
  }
}