import {Dispatch} from "redux";
import {DataService} from "./data.service";
import {GET_DATA_FAILURE, GET_DATA_REQUEST, GET_DATA_SUCCESS} from "./data.types";
import {toast} from "react-toastify";

const fetchData = (data: {zip: string, category: string}) : (dispatch: Dispatch) => void => {
  
  return (dispatch) => {
    dispatch({type: GET_DATA_REQUEST});
    DataService.getData(data.zip, data.category).then((data) => {
      dispatch({type: GET_DATA_SUCCESS, payload: data});
    }).catch((error) => {
      dispatch({type: GET_DATA_FAILURE, payload: error});
      if(error.response.status === 429)
        toast("Too many attempts, try on the next day!", {type: "error", autoClose: 2000});
    })
  }
}

export {
  fetchData
}