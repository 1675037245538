import {get, post, put} from "../../../middleware/api-proxy";
import {CreateUserType, UpdateUserType} from "./usersList.types";

export class UsersListService {
  
  static async getUsersList(page: number, take: number) {
    return get(`/users?order=ASC&page=${page}&take=${take}`)
  }
  
  static async searchUsers(search: string) {
    return get(`/users/search/${search}`)
  }
  
  static async updateUser(data: UpdateUserType, id: string) {
    return put(`/users/${id}`, data)
  }
  
  static async createUser(data: CreateUserType) {
    return post(`/auth/register`, data)
  }
  
}