import {combineReducers, configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {userReducer} from "./user/user.reducer";
import {redirectReducer} from "./redirect/redirect.reducer";
import {categoriesReducer} from "./categories/categories.reducer";
import {dataReducer} from "./data/data.reducer";
import {usersListReducer} from "./admin/usersList/usersList.reducer";


const middleware = [thunk];


const rootReducer = combineReducers({
  user: userReducer,
  redirect: redirectReducer,
  categories: categoriesReducer,
  data: dataReducer,
  admin: combineReducers({
    users: usersListReducer
  })
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(middleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
