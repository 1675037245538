import {Form} from "../../components/user/form/form";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {Login} from "../login/login";
import {Table} from "../../components/user/table/table";

export const Main = () => {
  
  document.title = 'Search';
  
  const {user} = useAppSelector((state: RootState) => state.user);
  
  return (
    user ?
    <>
      <Form/>
      <Table/>
    </>
      : <Login/>
  )
}