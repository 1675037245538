const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

type AuthLoginType = {
  email: string;
  password: string;
}

type UserType = {
  user: UserDataType,
  token: {
    accessToken: string;
    expiresIn: number;
  }
}

type UserDataType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  isActive: boolean;
}

type UserState = {
  user: UserType | null;
  isLoading: boolean;
  error: Error | null
}

type CreateAdminType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isActive: boolean;
  secretRootKey: string;
}

export type {
  AuthLoginType,
  UserType,
  UserState,
  CreateAdminType,
  UserDataType
}

export {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
}