import {UpdateUserType, UserType} from "../../../store/admin/usersList/usersList.types";
import {Button, Checkbox, Form, Input, Modal} from "antd";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {updateUser} from "../../../store/admin/usersList/usersList.action";
import {useState} from "react";

interface EditUserModalProps {
  user: UserType | null;
}

export const EditUserModal = ({user}: EditUserModalProps) => {
  
  const [isOpened, setIsOpened] = useState(false);
  
  const [form] = Form.useForm();
  
  const dispatch = useAppDispatch();
  
  const handleOk = () => {
    form.submit();
    setIsOpened(false);
  }
  const handleCancel = () => {
    setIsOpened(false);
  }
  
  const onFinish = (values: UpdateUserType) => {
    dispatch(updateUser({...values, requestsAvailable: Number(values.requestsAvailable)}, user?.id as string))
  }
  
  return (
    <>
      <Button
        type={'primary'}
        onClick={() => setIsOpened(true)}
      >
        Edit
      </Button>
      {
        user ?
          <Modal title={'Edit user'} onOk={handleOk} onCancel={handleCancel} open={isOpened}>
            <p>Are you sure you want to edit user {user.firstName} {user.lastName}?</p>
            <Form
              initialValues={{...user}}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                labelCol={{style: {width: '120px'}}}
                label={'Email'}
                name={'email'}
              >
                <Input
                  placeholder={'Input email'}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                  labelCol={{style: {width: '120px'}}}
                  label={'Role'}
                  name={'role'}
              >
                <Input disabled placeholder={'Input role'}/>
              </Form.Item>
              <Form.Item
                labelCol={{style: {width: '120px'}}}
                label={'First name'}
                name={'firstName'}
                rules={[{required: true, message: 'Please input first name'}]}
              >
                <Input placeholder={'Input first name'}/>
              </Form.Item>
              <Form.Item
                labelCol={{style: {width: '120px'}}}
                label={'Last name'}
                name={'lastName'}
                rules={[{required: true, message: 'Please input last name'}]}
              >
                <Input placeholder={'Input last name'}/>
              </Form.Item>
              <Form.Item
                labelCol={{style: {width: '120px'}}}
                label={'Is active?'}
                name={'isActive'}
              >
                <Checkbox checked={user.isActive}/>
              </Form.Item>
              <Form.Item
                labelCol={{style: {width: '120px'}}}
                label={'Requests amount'}
                name={'requestsAvailable'}
              >
                <Input placeholder={'Input requests amount per day'}/>
              </Form.Item>
              <Form.Item
                labelCol={{style: {width: '120px'}}}
                label={'New Password'}
                name={'password'}
              >
                <Input placeholder={'Input new password'} type={'password'}/>
              </Form.Item>
            </Form>
          </Modal>
          : null
      }
    </>
  )
}