import {AUTH_LOGIN_FAILURE, AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, UserState, UserType} from "./user.types";
import {ActionWithPayload} from "../index.types";

const initialState: UserState = {
  user: null,
  isLoading: false,
  error: null
}

export const userReducer = (state = initialState, action: ActionWithPayload<UserType | Error>): UserState => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload as UserType,
      }
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload as Error,
      }
    default:
      return state;
  }
}