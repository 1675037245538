import {Button, Checkbox, Form, Input, Modal} from "antd";
import {useState} from "react";
import {CreateUserType} from "../../../store/admin/usersList/usersList.types";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {createUser} from "../../../store/admin/usersList/usersList.action";
import { RuleObject } from 'antd/lib/form';

export const AddUserModal = () => {
  
  const [isOpened, setIsOpened] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  
  const handleOk = async () => {
    await form.validateFields() && setIsOpened(false);
    form.submit();
  }
  
  const onFinish = (values: CreateUserType) => {
    dispatch(createUser({...values, requestsAvailable: Number(values.requestsAvailable), requestsRemaining: Number(values.requestsAvailable)}))
    form.resetFields();
  }

  const validateEmail = (rule: RuleObject, value: string, callback: (message?: string) => void) => {
    if (value && !/\S+@\S+\.\S+/.test(value)) {
      callback('Please enter a valid email address');
    } else {
      callback();
    }
  };
  
  return (
    <>
      <Button
        type={'primary'}
        onClick={() => setIsOpened(true)}
      >
        Add User
      </Button>
      <Modal title={'Create User'} onOk={handleOk} onCancel={() => setIsOpened(false)} open={isOpened}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{requestsAvailable: 25, isActive: true}}
        >
          <Form.Item
            labelCol={{style: {width: '120px'}}}
            label={'Email'}
            name={'email'}
            rules={[{ validator: validateEmail }]}
          >
            <Input
              placeholder={'Input email'}
            />
          </Form.Item>
          <Form.Item
            labelCol={{style: {width: '120px'}}}
            label={'First name'}
            name={'firstName'}
            rules={[{required: true, message: 'Please input first name'}]}
          >
            <Input placeholder={'Input first name'}/>
          </Form.Item>
          <Form.Item
            labelCol={{style: {width: '120px'}}}
            label={'Last name'}
            name={'lastName'}
            rules={[{required: true, message: 'Please input last name'}]}
          >
            <Input placeholder={'Input last name'}/>
          </Form.Item>
          <Form.Item
            labelCol={{style: {width: '120px'}}}
            label={'Is active?'}
            name={'isActive'}
          >
            <Checkbox/>
          </Form.Item>
          <Form.Item
            labelCol={{style: {width: '120px'}}}
            label={'Requests amount'}
            name={'requestsAvailable'}
          >
            <Input placeholder={'Input requests amount per day'}/>
          </Form.Item>
          <Form.Item
            labelCol={{style: {width: '120px'}}}
            label={'Password'}
            name={'password'}
            rules={[{required: true, message: 'Please input password'}]}
          >
            <Input placeholder={'Input password'} type={'password'}/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}