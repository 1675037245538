import {Button, Checkbox, Form, Input} from "antd";
import {CreateAdminType} from "../../../store/user/user.types";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {createAdmin} from "../../../store/user/user.action";

export const CreateAdmin = () => {
  document.title = 'Create Admin';
  
  const dispatch = useAppDispatch();
  
  const onFinish = (values: CreateAdminType) => {
    dispatch(createAdmin({...values, isActive: values.isActive}));
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
  
      <Form.Item
        label="First name"
        name="firstName"
        rules={[{ required: true, message: 'Please input your first name!' }]}
      >
        <Input />
      </Form.Item>
  
      <Form.Item
        label="Last name"
        name="lastName"
        rules={[{ required: true, message: 'Please input your last name!' }]}
      >
        <Input />
      </Form.Item>
      
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input />
      </Form.Item>
    
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>
  
      <Form.Item
        label="Is active?"
        name="isActive"
        valuePropName="checked"
      >
        <Checkbox>Active</Checkbox>
      </Form.Item>
  
      <Form.Item
        label="Secret key"
        name="secretRootKey"
        rules={[{ required: true, message: 'Please input secret root key!' }]}
      >
        <Input.Password />
      </Form.Item>
    
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}