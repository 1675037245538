const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

type CategoriesState = {
  categories: CategoriesPayload['categories'];
  isLoading: boolean;
  error: Error | null;
}

type CategoriesPayload = {
  categories: string[];
}

export type {
  CategoriesState,
  CategoriesPayload
}

export {
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_REQUEST
}