const GET_DATA_REQUEST = 'GET_DATA_REQUEST';
const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
const GET_DATA_FAILURE = 'GET_DATA_FAILURE';


type DataState = {
  data: DataType[],
  isLoading: boolean,
  error: null | Error,
}

type DataType = {
  fipsstate: number,
  statename: string,
  fipscounty: number,
  countyname: string,
  categoryname: string,
  policyname: string,
  startdate: string,
  startdate_url: string,
  zip: number,
  ziplookup: string,
  cityname: string,
  fipscountycode: number,
  fipslookup: string,
  countryname: string,
  stateorequivalentname: string,
  statecode: string,
}

export type {
  DataState,
  DataType
}

export {
  GET_DATA_FAILURE,
  GET_DATA_SUCCESS,
  GET_DATA_REQUEST
}