import {Dispatch} from "redux";
import {CategoriesService} from "./categories.service";
import {CategoriesPayload, GET_CATEGORIES_FAILURE} from "./categories.types";
import {GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS} from "./categories.types";

const fetchCategories = () : (dispatch: Dispatch) => void => {
  
  return (dispatch) => {
    dispatch({type: GET_CATEGORIES_REQUEST});
    CategoriesService.getCategories().then((data: CategoriesPayload) => {
      dispatch({type: GET_CATEGORIES_SUCCESS, payload: data.categories});
    }).catch((error) => {
      dispatch({type: GET_CATEGORIES_FAILURE, payload: error});
    })
  }
}

export {
  fetchCategories
}